

export async function fetchWithAuth(url, options = {}) {
    const token = localStorage.getItem('authToken');
  
   
    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  
    const response = await fetch(url, {
      ...options,
      headers,
    });
  
   
    if (response.status === 401) {
    
      localStorage.removeItem('authToken');
      window.location.href = '/login';
    }
  
    return response;
  }
  