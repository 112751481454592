import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ModuleDetails from './components/ModuleDetails';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';
import EditModule from './components/EditModule';
import AddExamRegulation from './components/AddExamRegulation';  
import EditExamRegulation from './components/EditExamRegulation'; 
import AddModule from './components/AddModule';
import AddModuleMaster from './components/AddModuleMaster';
import AddExamRegulationMaster from './components/AddExamRegulationMaster';
import MasterModuleDetails from './components/MasterModuleDetails';
import EditExamRegulationMaster from './components/EditExamRegulationMaster'; 

import './styles.css';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <header
        className="hero-section"
        style={{
          backgroundImage: "url('/hero-banner.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '400px',
          color: 'white',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        ></div>
        <div style={{ zIndex: 1 }}>
          <h1 className="text-white">Modulplan</h1>
        </div>
      </header>

      <div className="app-container">
        <Sidebar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/module/:id" element={<ModuleDetails />} />
            <Route path="/admin" element={authenticated ? <AdminPanel /> : <Login setAuthenticated={setAuthenticated} />} />
            <Route path="/login" element={<Login setAuthenticated={setAuthenticated} />} />
            <Route path="/admin/edit/:id" element={authenticated ? <EditModule /> : <Login setAuthenticated={setAuthenticated} />} /> 
            <Route path="/admin/add" element={authenticated ? <AddModule /> : <Login setAuthenticated={setAuthenticated} />} />
            <Route path="/admin/addExamRegulation" element={authenticated ? <AddExamRegulation /> : <Login setAuthenticated={setAuthenticated} />} /> 
            <Route path="/admin/editExamRegulation/:id" element={authenticated ? <EditExamRegulation /> : <Login setAuthenticated={setAuthenticated} />} />  

           
            <Route path="/master/informatics/:id" element={<MasterModuleDetails />} />
            <Route path="/master/applications/:id" element={<MasterModuleDetails />} />
            <Route path="/admin/addModuleMaster" element={authenticated ? <AddModuleMaster /> : <Login setAuthenticated={setAuthenticated} />} />
            <Route path="/admin/addExamRegulationMaster" element={authenticated ? <AddExamRegulationMaster /> : <Login setAuthenticated={setAuthenticated} />} />
            <Route path="/admin/editExamRegulationMaster/:id" element={authenticated ? <EditExamRegulationMaster /> : <Login setAuthenticated={setAuthenticated} />} />

          </Routes>
        </div>
      </div>
    </Router>
  );
}

function Welcome() {
  return <h2>Willkommen zum Modulplan</h2>;
}

export default App;
