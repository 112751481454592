import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { fetchWithAuth } from "../helpers/fetchWithAuth"; 

function EditExamRegulationMaster() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/MasterExamRegulations.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => setName(data.name));
  }, [id]);

  const handleSave = () => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/backend/MasterExamRegulations.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, name }),
    }).then(() => {
      navigate("/admin");
    });
  };

  return (
    <div className="container">
      <h2 className="my-4">Master Prüfungsordnung bearbeiten</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Name der Prüfungsordnung</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleSave}>
          Speichern
        </Button>
      </Form>
    </div>
  );
}

export default EditExamRegulationMaster;
