import React, { useState, useEffect } from "react";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { Link, Route, Routes } from "react-router-dom";
import EditExamRegulation from "./EditExamRegulation";
import AddExamRegulation from "./AddExamRegulation";
import AddModule from "./AddModule";
import EditExamRegulationMaster from "./EditExamRegulationMaster";
import AddExamRegulationMaster from "./AddExamRegulationMaster";
import AddModuleMaster from "./AddModuleMaster";
import EditModuleMaster from "./EditModuleMaster";
import './AdminPanel.css'; 

function AdminPanel() {
  const [bachelorModules, setBachelorModules] = useState([]);
  const [masterModules, setMasterModules] = useState([]);
  const [bachelorExamRegulations, setBachelorExamRegulations] = useState([]);
  const [masterExamRegulations, setMasterExamRegulations] = useState([]);


  const token = localStorage.getItem('authToken');

  
  useEffect(() => {
    
    fetch(`${process.env.REACT_APP_API_BASE_URL}/modules.php`, {
      headers: {
        "Authorization": `Bearer ${token}` 
      }
    })
      .then((response) => response.json())
      .then((data) => setBachelorModules(data));

  
    fetch(`${process.env.REACT_APP_API_BASE_URL}/MasterModules.php`, {
      headers: {
        "Authorization": `Bearer ${token}` 
      }
    })
      .then((response) => response.json())
      .then((data) => setMasterModules(data));

   
    fetch(`${process.env.REACT_APP_API_BASE_URL}/examRegulations.php`, {
      headers: {
        "Authorization": `Bearer ${token}`  
      }
    })
      .then((response) => response.json())
      .then((data) => setBachelorExamRegulations(data));

  
    fetch(`${process.env.REACT_APP_API_BASE_URL}/MasterExamRegulations.php`, {
      headers: {
        "Authorization": `Bearer ${token}` 
      }
    })
      .then((response) => response.json())
      .then((data) => setMasterExamRegulations(data));
  }, [token]);

  const handleDeleteModule = (id, program) => {
    const endpoint = program === 'bachelor' ? 'modules.php' : 'MasterModules.php';
    if (window.confirm("Möchten Sie dieses Modul wirklich löschen?")) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
        method: "DELETE",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` 
        },
        body: JSON.stringify({ id }),
      }).then(() => {
        if (program === 'bachelor') {
          setBachelorModules(bachelorModules.filter((module) => module.id !== id));
        } else {
          setMasterModules(masterModules.filter((module) => module.id !== id));
        }
      });
    }
  };

  const handleDeleteExamRegulation = (id, program) => {
    const endpoint = program === 'bachelor' ? 'examRegulations.php' : 'MasterExamRegulations.php';
    if (window.confirm("Möchten Sie diese Prüfungsordnung wirklich löschen?")) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
        method: "DELETE",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` 
        },
        body: JSON.stringify({ id }),
      }).then(() => {
        if (program === 'bachelor') {
          setBachelorExamRegulations(bachelorExamRegulations.filter((reg) => reg.id !== id));
        } else {
          setMasterExamRegulations(masterExamRegulations.filter((reg) => reg.id !== id));
        }
      });
    }
  };

  return (
    <Container className="admin-panel mt-5">
      <h2 className="text-center mb-4">Admin Bereich: Bachelor und Master Module und Prüfungsordnungen</h2>

      {/* Bachelor Section */}
      <h3 className="text-center mb-4">Bachelor Module</h3>
      <Row className="mb-4 justify-content-center">
        <Col md={6}>
          <Button variant="primary" as={Link} to="/admin/add" className="w-100 mb-2">
            <i className="fas fa-plus me-2"></i> Neues Bachelor Modul hinzufügen
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover responsive className="shadow-sm rounded">
        <thead className="table-header">
          <tr>
            <th>#</th>
            <th>Titel</th>
            <th>ECTS</th>
            <th>Semester</th>
            <th>Prüfungsordnung</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {bachelorModules.map((module, index) => (
            <tr key={module.id}>
              <td>{index + 1}</td>
              <td>{module.title}</td>
              <td>{module.ects}</td>
              <td>{module.semester}</td>
              <td>
                {bachelorExamRegulations.find((reg) => reg.id === module.examRegulationId)?.name || "Keine"}
              </td>
              <td>
                <Button
                  variant="warning"
                  as={Link}
                  to={`/admin/edit/${module.id}`}
                  className="me-2"
                >
                  <i className="fas fa-edit"></i> Bearbeiten
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteModule(module.id, 'bachelor')}
                >
                  <i className="fas fa-trash"></i> Löschen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3 className="text-center mb-4">Bachelor Prüfungsordnungen</h3>
      <Table striped bordered hover responsive className="shadow-sm rounded">
        <thead className="table-header">
          <tr>
            <th>#</th>
            <th>Name der Prüfungsordnung</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {bachelorExamRegulations.map((regulation, index) => (
            <tr key={regulation.id}>
              <td>{index + 1}</td>
              <td>{regulation.name}</td>
              <td>
                <Button
                  variant="warning"
                  as={Link}
                  to={`/admin/editExamRegulation/${regulation.id}`}
                  className="me-2"
                >
                  <i className="fas fa-edit"></i> Bearbeiten
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteExamRegulation(regulation.id, 'bachelor')}
                >
                  <i className="fas fa-trash"></i> Löschen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Master Section */}
      <h3 className="text-center mb-4">Master Module</h3>
      <Row className="mb-4 justify-content-center">
        <Col md={6}>
          <Button variant="primary" as={Link} to="/admin/addModuleMaster" className="w-100 mb-2">
            <i className="fas fa-plus me-2"></i> Neues Master Modul hinzufügen
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover responsive className="shadow-sm rounded">
        <thead className="table-header">
          <tr>
            <th>#</th>
            <th>Titel</th>
            <th>ECTS</th>
            <th>Kategorie</th>
            <th>Prüfungsordnung</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {masterModules.map((module, index) => (
            <tr key={module.id}>
              <td>{index + 1}</td>
              <td>{module.title}</td>
              <td>{module.ects}</td>
              <td>{module.category}</td>
              <td>
                {masterExamRegulations.find((reg) => reg.id === module.examRegulationId)?.name || "Keine"}
              </td>
              <td>
                <Button
                  variant="warning"
                  as={Link}
                  to={`/admin/editMasterModule/${module.id}`}
                  className="me-2"
                >
                  <i className="fas fa-edit"></i> Bearbeiten
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteModule(module.id, 'master')}
                >
                  <i className="fas fa-trash"></i> Löschen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3 className="text-center mb-4">Master Prüfungsordnungen</h3>
      <Row className="mb-4 justify-content-center">
        <Col md={6}>
          <Button variant="success" as={Link} to="/admin/addExamRegulationMaster" className="w-100 mb-2">
            <i className="fas fa-plus me-2"></i> Neue Master Prüfungsordnung hinzufügen
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover responsive className="shadow-sm rounded">
        <thead className="table-header">
          <tr>
            <th>#</th>
            <th>Name der Prüfungsordnung</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {masterExamRegulations.map((regulation, index) => (
            <tr key={regulation.id}>
              <td>{index + 1}</td>
              <td>{regulation.name}</td>
              <td>
                <Button
                  variant="warning"
                  as={Link}
                  to={`/admin/editExamRegulationMaster/${regulation.id}`}
                  className="me-2"
                >
                  <i className="fas fa-edit"></i> Bearbeiten
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteExamRegulation(regulation.id, 'master')}
                >
                  <i className="fas fa-trash"></i> Löschen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      
      <Routes>
        <Route path="/admin/add" element={<AddModule />} />
        <Route path="/admin/addModuleMaster" element={<AddModuleMaster />} />
        <Route path="/admin/addExamRegulation" element={<AddExamRegulation />} />
        <Route path="/admin/addExamRegulationMaster" element={<AddExamRegulationMaster />} />
        <Route path="/admin/editExamRegulation/:id" element={<EditExamRegulation />} />
        <Route path="/admin/editExamRegulationMaster/:id" element={<EditExamRegulationMaster />} />
        <Route path="/admin/editMasterModule/:id" element={<EditModuleMaster />} />
      </Routes>
    </Container>
  );
}

export default AdminPanel;
