import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { fetchWithAuth } from "../helpers/fetchWithAuth"; 
import './ModuleForm.css';  

function EditModule() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    ects: "",
    semester: "",
    learningGoals: "",
    content: "",
    faculty: "",
    usability: "",
    duration: "",
    language: "",
    teaching: "",
    examinations: "",
    literature: "",
    miscellaneous: "",
    examRegulationId: "",
  });

  const [examRegulations, setExamRegulations] = useState([]);

  useEffect(() => {
    if (id) {
      fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/modules.php?id=${id}`)
        .then((response) => response.json())
        .then((data) => setFormData(data));
    }

    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/examRegulations.php`)
      .then((response) => response.json())
      .then((data) => setExamRegulations(data));
  }, [id]);

  const handleSave = () => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/modules.php`, {
      method: "PUT",
      body: JSON.stringify({ id, ...formData }),
    }).then(() => {
      navigate("/admin");
    });
  };

  return (
    <div className="form-container">
      <h2>Modul bearbeiten</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Titel</Form.Label>
          <Form.Control
            type="text"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Beschreibung</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>ECTS</Form.Label>
          <Form.Control
            type="number"
            value={formData.ects}
            onChange={(e) => setFormData({ ...formData, ects: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Semester</Form.Label>
          <Form.Control
            type="text"
            value={formData.semester}
            onChange={(e) => setFormData({ ...formData, semester: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Verwendbarkeit</Form.Label>
          <Form.Control
            type="text"
            value={formData.usability}
            onChange={(e) => setFormData({ ...formData, usability: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Verantwortliche Fakultät</Form.Label>
          <Form.Control
            type="text"
            value={formData.faculty}
            onChange={(e) => setFormData({ ...formData, faculty: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Lernziele</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.learningGoals}
            onChange={(e) => setFormData({ ...formData, learningGoals: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Inhalte</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dauer</Form.Label>
          <Form.Control
            type="text"
            value={formData.duration}
            onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Sprache</Form.Label>
          <Form.Control
            type="text"
            value={formData.language}
            onChange={(e) => setFormData({ ...formData, language: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Lehrveranstaltungen</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.teaching}
            onChange={(e) => setFormData({ ...formData, teaching: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Prüfungsleistungen</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.examinations}
            onChange={(e) => setFormData({ ...formData, examinations: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Literatur</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.literature}
            onChange={(e) => setFormData({ ...formData, literature: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Sonstiges</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.miscellaneous}
            onChange={(e) => setFormData({ ...formData, miscellaneous: e.target.value })}
          />
        </Form.Group>
        
        {/* Prüfungsordnung Dropdown */}
        <Form.Group className="mb-3">
          <Form.Label>Prüfungsordnung</Form.Label>
          <Form.Control
            as="select"
            value={formData.examRegulationId}  
            onChange={(e) => setFormData({ ...formData, examRegulationId: e.target.value })}
          >
            <option value="">Bitte wählen</option>
            {examRegulations.map((regulation) => (
              <option key={regulation.id} value={regulation.id}>
                {regulation.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <div className="button-group">
          <Button variant="secondary" onClick={() => navigate("/admin")}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditModule;
