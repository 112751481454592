import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faBullseye, faListAlt, faChalkboardTeacher, faEdit, faBook, faInfo } from "@fortawesome/free-solid-svg-icons";

function MasterModuleDetails() {
  const { id } = useParams(); 
  const [module, setModule] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/MasterModules.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setModule(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Fehler beim Laden des Master-Moduls:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Lädt...</div>;
  }

  if (!module) {
    return <div>Fehler: Modul nicht gefunden.</div>;
  }

  return (
    <div>
      {/* Main Content */}
      <Container>
        <Row>
          <Col>
            <h2 className="mb-4">{module.title}</h2>
          </Col>
        </Row>

        {/* Modulübersicht */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                Modulübersicht
              </Card.Header>
              <Card.Body>
                <p>
                  <strong>Modultitel (deutsch):</strong> {module.title}
                </p>
                <p>
                  <strong>Kategorie:</strong> {module.category}
                </p>
                <p>
                  <strong>Unterkategorie:</strong> {module.subcategory}
                </p>
                <p>
                  <strong>Verwendbarkeit:</strong> {module.usability}
                </p>
                <p>
                  <strong>Verantwortliche Fakultät:</strong> {module.faculty}
                </p>
                <p>
                  <strong>Leistungspunkte:</strong> {module.ects} ECTS
                </p>
                <p>
                  <strong>Dauer:</strong> {module.duration}
                </p>
                <p>
                  <strong>Sprache:</strong> {module.language}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Lernziele */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faBullseye} className="me-2" />
                Lernziele
              </Card.Header>
              <Card.Body>
                <ul className="list-group list-group-flush">
                  {module.learningGoals?.split("\n").map((goal, index) => (
                    <li className="list-group-item" key={index}>
                      {goal}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Inhalte */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faListAlt} className="me-2" />
                Inhalte
              </Card.Header>
              <Card.Body>
                <ul className="list-group list-group-flush">
                  {module.content?.split("\n").map((item, index) => (
                    <li className="list-group-item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Lehrveranstaltungen */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faChalkboardTeacher} className="me-2" />
                Lehrveranstaltungen
              </Card.Header>
              <Card.Body>
                <ul className="list-group list-group-flush">
                  {module.teaching?.split("\n").map((item, index) => (
                    <li className="list-group-item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Prüfungsleistungen */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Prüfungsleistungen
              </Card.Header>
              <Card.Body>
                <ul className="list-group list-group-flush">
                  {module.examinations?.split("\n").map((item, index) => (
                    <li className="list-group-item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Literatur */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faBook} className="me-2" />
                Literatur
              </Card.Header>
              <Card.Body>
                <ul className="list-group list-group-flush">
                  {module.literature?.split("\n").map((item, index) => (
                    <li className="list-group-item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Sonstiges */}
        <Row>
          <Col>
            <Card className="mb-4 shadow-sm rounded-card">
              <Card.Header className="bg-info text-white">
                <FontAwesomeIcon icon={faInfo} className="me-2" />
                Sonstiges
              </Card.Header>
              <Card.Body>
                <p>{module.miscellaneous}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MasterModuleDetails;
