import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { fetchWithAuth } from "../helpers/fetchWithAuth"; 

function AddExamRegulation() {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const handleSave = () => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/examRegulations.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then((response) => response.json())
      .then(() => {
        navigate("/admin"); 
      });
  };

  return (
    <div className="container">
      <h2 className="my-4">Neue Prüfungsordnung hinzufügen</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Name der Prüfungsordnung</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleSave}>
          Speichern
        </Button>
      </Form>
    </div>
  );
}

export default AddExamRegulation;
