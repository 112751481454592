import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import './ModuleForm.css';  
import { fetchWithAuth } from "../helpers/fetchWithAuth"; 

function AddModuleMaster() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    ects: "",
    category: "",  
    subcategory: "", 
    learningGoals: "",
    content: "",
    faculty: "",
    usability: "",
    duration: "",
    language: "",
    teaching: "",
    examinations: "",
    literature: "",
    miscellaneous: "",
    examRegulationId: "",
  });

  const [examRegulations, setExamRegulations] = useState([]);

  const informatikDisziplinSubcategories = [
    "Cooperative Human-Machine Interaction",
    "Engineering Methods and Dependability",
    "Machine Learning and Big Data",
    "Smart Cyber-Physical Systems"
  ];

  const anwendungsgebieteSubcategories = [
    "Autonome Systeme",
    "Circular Economy und Umwelttechnik",
    "Digitale Transformation",
    "Energie",
    "Industrie 4.0",
    "Mobilität"
  ];

  useEffect(() => {

    fetchWithAuth(`h${process.env.REACT_APP_API_BASE_URL}/MasterExamRegulations.php`)
      .then((response) => response.json())
      .then((data) => setExamRegulations(data));
  }, []);

  const handleSave = () => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/MasterModules.php`, {
      method: "POST",  
      headers: { 
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData),
    }).then(() => {
      navigate("/admin");
    });
  };

  return (
    <div className="container">
      <h2 className="my-4">Neues Master Modul hinzufügen</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Titel</Form.Label>
          <Form.Control
            type="text"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Beschreibung</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>ECTS</Form.Label>
          <Form.Control
            type="number"
            value={formData.ects}
            onChange={(e) => setFormData({ ...formData, ects: e.target.value })}
          />
        </Form.Group>
        
        {/* Kategorie für Master-Modul */}
        <Form.Group className="mb-3">
          <Form.Label>Kategorie</Form.Label>
          <Form.Control
            as="select"
            value={formData.category}
            onChange={(e) => setFormData({ ...formData, category: e.target.value, subcategory: "" })}
          >
            <option value="">Bitte wählen</option>
            <option value="Informatik Disziplin">Informatik Disziplin</option>
            <option value="Anwendungsgebiet">Anwendungsgebiet</option>
          </Form.Control>
        </Form.Group>
        
        {/* Dropdown für die spezifische Unterkategorie  */}
        {formData.category === "Informatik Disziplin" && (
          <Form.Group className="mb-3">
            <Form.Label>Unterkategorie (Informatik Disziplin)</Form.Label>
            <Form.Control
              as="select"
              value={formData.subcategory}
              onChange={(e) => setFormData({ ...formData, subcategory: e.target.value })}
            >
              <option value="">Bitte wählen</option>
              {informatikDisziplinSubcategories.map((sub) => (
                <option key={sub} value={sub}>
                  {sub}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {formData.category === "Anwendungsgebiet" && (
          <Form.Group className="mb-3">
            <Form.Label>Unterkategorie (Anwendungsgebiet)</Form.Label>
            <Form.Control
              as="select"
              value={formData.subcategory}
              onChange={(e) => setFormData({ ...formData, subcategory: e.target.value })}
            >
              <option value="">Bitte wählen</option>
              {anwendungsgebieteSubcategories.map((sub) => (
                <option key={sub} value={sub}>
                  {sub}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Form.Label>Verwendbarkeit</Form.Label>
          <Form.Control
            type="text"
            value={formData.usability}
            onChange={(e) => setFormData({ ...formData, usability: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Verantwortliche Fakultät</Form.Label>
          <Form.Control
            type="text"
            value={formData.faculty}
            onChange={(e) => setFormData({ ...formData, faculty: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Lernziele</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.learningGoals}
            onChange={(e) => setFormData({ ...formData, learningGoals: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Inhalte</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dauer</Form.Label>
          <Form.Control
            type="text"
            value={formData.duration}
            onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Sprache</Form.Label>
          <Form.Control
            type="text"
            value={formData.language}
            onChange={(e) => setFormData({ ...formData, language: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Lehrveranstaltungen</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.teaching}
            onChange={(e) => setFormData({ ...formData, teaching: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Prüfungsleistungen</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.examinations}
            onChange={(e) => setFormData({ ...formData, examinations: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Literatur</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.literature}
            onChange={(e) => setFormData({ ...formData, literature: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Sonstiges</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.miscellaneous}
            onChange={(e) => setFormData({ ...formData, miscellaneous: e.target.value })}
          />
        </Form.Group>
        
        {/* Dropdown für Prüfungsordnung */}
        <Form.Group className="mb-3">
          <Form.Label>Prüfungsordnung</Form.Label>
          <Form.Control
            as="select"
            value={formData.examRegulationId}
            onChange={(e) => setFormData({ ...formData, examRegulationId: e.target.value })}
          >
            <option value="">Bitte wählen</option>
            {examRegulations.map((regulation) => (
              <option key={regulation.id} value={regulation.id}>
                {regulation.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" onClick={handleSave}>
          Speichern
        </Button>
      </Form>
    </div>
  );
}

export default AddModuleMaster;
