import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCalendarAlt, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Sidebar.css";

function Sidebar() {
  const [program, setProgram] = useState("bachelor");
  const [modules, setModules] = useState([]);
  const [openSection, setOpenSection] = useState({});
  const [openSubsection, setOpenSubsection] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [examRegulations, setExamRegulations] = useState([]);
  const [selectedRegulation, setSelectedRegulation] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  // Subcategories for Informatik Disziplin and Anwendungsgebiete
  const informatikDisziplinSubcategories = [
    "Cooperative Human-Machine Interaction",
    "Engineering Methods and Dependability",
    "Machine Learning and Big Data",
    "Smart Cyber-Physical Systems",
  ];

  const anwendungsgebieteSubcategories = [
    "Autonome Systeme",
    "Circular Economy und Umwelttechnik",
    "Digitale Transformation",
    "Energie",
    "Industrie 4.0",
    "Mobilität",
  ];

  // Fetch Modules
  const fetchModules = useCallback(() => {
    const endpoint = program === "bachelor" ? "modules.php" : "masterModules.php";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`)
      .then((response) => response.json())
      .then((data) => {
        setModules(data);
      })
      .catch((error) => console.error("Fehler beim Abrufen der Module:", error));
  }, [program]);

  // Fetch Exam Regulations
  const fetchExamRegulations = useCallback(() => {
    const examEndpoint = program === "bachelor" ? "examRegulations.php" : "masterExamRegulations.php";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/${examEndpoint}`)
      .then((response) => response.json())
      .then((data) => {
        setExamRegulations(data);
      })
      .catch((error) => console.error("Fehler beim Abrufen der Prüfungsordnungen:", error));
  }, [program]);

  // Update modules and exam regulations when program changes
  useEffect(() => {
    fetchModules();
    fetchExamRegulations();
  }, [fetchModules, fetchExamRegulations]);

  // Group Modules by Semester or Category
  const groupedModules = modules.reduce((acc, module) => {
    if (program === "bachelor") {
      (acc[module.semester] = acc[module.semester] || []).push(module);
    } else {
      const category = module.category || "Andere";
      (acc[category] = acc[category] || []).push(module);
    }
    return acc;
  }, {});

  // Toggle Logic for Sections and Subsections
  const toggleSection = (section) => {
    setOpenSection((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const toggleSubsection = (subcategory) => {
    setOpenSubsection((prev) => ({
      ...prev,
      [subcategory]: !prev[subcategory],
    }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Filter Modules based on Exam Regulation and Search Term
  const filteredModules = modules.filter((module) => {
    return (
      (selectedRegulation === "all" || module.examRegulationId === selectedRegulation) &&
      module.title?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      {/* Mobile Navbar */}
      <div className="mobile-navbar">
        <button className="hamburger-menu" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      {/* Sidebar */}
      <div className={`sidebar-wrapper ${isSidebarOpen ? "open" : ""}`}>
        <h3 className="sidebar-header">Studiengang wählen</h3>
        <div className="program-selector">
          <button
            className={`btn btn-sm ${program === "bachelor" ? "btn-primary" : "btn-outline-primary"}`}
            onClick={() => setProgram("bachelor")}
          >
            Bachelor
          </button>
          <button
            className={`btn btn-sm ${program === "master" ? "btn-primary" : "btn-outline-primary"}`}
            onClick={() => setProgram("master")}
          >
            Master
          </button>
        </div>

        <h3 className="sidebar-header">Suche</h3>
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Module suchen..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}  
        />

        <h3 className="sidebar-header">Filter nach Prüfungsordnung</h3>
        <select
          className="form-select mb-3"
          onChange={(e) => setSelectedRegulation(parseInt(e.target.value))}
          value={selectedRegulation || ""}
        >
          
          {examRegulations.map((regulation) => (
            <option key={regulation.id} value={regulation.id}>
              {regulation.name}
            </option>
          ))}
        </select>

        {program === "bachelor" ? (
          <>
            <h3 className="sidebar-header">Semester</h3>
            {Object.entries(groupedModules).map(([semester, modules], index) => (
              <div key={semester} className="semester-section">
                <div className="semester-header" onClick={() => toggleSection(semester)}>
                  <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                  Semester {semester}
                  <FontAwesomeIcon
                    icon={openSection[semester] ? faChevronDown : faChevronRight}
                    className="ms-auto"
                  />
                </div>
                {openSection[semester] && (
                  <ul className="list-unstyled semester-modules">
                    {filteredModules
                      .filter((module) => module.semester === parseInt(semester))
                      .map((module) => (
                        <li key={module.id}>
                          <Link
                            to={`/module/${module.id}`}
                            className="text-decoration-none text-secondary"
                            onClick={toggleSidebar}
                          >
                            {module.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            <h3 className="sidebar-header">Informatik Disziplinen</h3>
            {groupedModules['Informatik Disziplin'] && (
              <div className="semester-section">
                <div className="semester-header" onClick={() => toggleSection('Informatik Disziplin')}>
                  Informatik Disziplinen
                  <FontAwesomeIcon
                    icon={openSection['Informatik Disziplin'] ? faChevronDown : faChevronRight}
                    className="ms-auto"
                  />
                </div>
                {openSection['Informatik Disziplin'] && (
                  <ul className="list-unstyled semester-modules">
                    {informatikDisziplinSubcategories.map((subcategory) => (
                      <li key={subcategory}>
                        <div className="semester-header" onClick={() => toggleSubsection(subcategory)}>
                          {subcategory}
                          <FontAwesomeIcon
                            icon={openSubsection[subcategory] ? faChevronDown : faChevronRight}
                            className="ms-auto"
                          />
                        </div>
                        {openSubsection[subcategory] && (
                          <ul className="list-unstyled semester-modules">
                            {filteredModules
                              .filter((module) => module.subcategory === subcategory)
                              .map((module) => (
                                <li key={module.id}>
                                  <Link
                                    to={`/master/informatics/${module.id}`}
                                    className="text-decoration-none text-secondary"
                                    onClick={toggleSidebar}
                                  >
                                    {module.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            <h3 className="sidebar-header">Anwendungsgebiete</h3>
            {groupedModules['Anwendungsgebiet'] && (
              <div className="semester-section">
                <div className="semester-header" onClick={() => toggleSection('Anwendungsgebiet')}>
                  Anwendungsgebiete
                  <FontAwesomeIcon
                    icon={openSection['Anwendungsgebiet'] ? faChevronDown : faChevronRight}
                    className="ms-auto"
                  />
                </div>
                {openSection['Anwendungsgebiet'] && (
                  <ul className="list-unstyled semester-modules">
                    {anwendungsgebieteSubcategories.map((subcategory) => (
                      <li key={subcategory}>
                        <div className="semester-header" onClick={() => toggleSubsection(subcategory)}>
                          {subcategory}
                          <FontAwesomeIcon
                            icon={openSubsection[subcategory] ? faChevronDown : faChevronRight}
                            className="ms-auto"
                          />
                        </div>
                        {openSubsection[subcategory] && (
                          <ul className="list-unstyled semester-modules">
                            {filteredModules
                              .filter((module) => module.subcategory === subcategory)
                              .map((module) => (
                                <li key={module.id}>
                                  <Link
                                    to={`/master/applications/${module.id}`}
                                    className="text-decoration-none text-secondary"
                                    onClick={toggleSidebar}
                                  >
                                    {module.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </>
        )}

        {/* Datenschutz und Impressum */}
        <div className="sidebar-footer">
          <h5 className="sidebar-header">Weitere Links</h5>
          <ul className="list-unstyled semester-modules">
            <li>
              <a href="https://www.digitecstudieren.de/datenschutz" className="text-decoration-none text-secondary">
                Datenschutz
              </a>
            </li>
            <li>
              <a href="https://www.digitecstudieren.de/impressum" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-secondary">
                Impressum
              </a>
            </li>
            <li>
              <a href="https://www.digitecstudieren.de/fileadmin/Digitecstudieren/documents/DT-Bachelor/Modulverantwortliche_Bachelor_2023.pdf" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-secondary">
              Modulverantwortliche, B.Sc.

              </a>
            </li>
            <li>
              <a href="https://www.digitecstudieren.de/fileadmin/Digitecstudieren/documents/DT-Master/Modulverantwortlich_M.Sc._DigiTec.pdf" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-secondary">
              Modulverantwortliche, M.Sc.
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
